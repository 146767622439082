<template>
  <div>
    <div class="page-header panel">
      <h2 class="title">
        Дэлгэрэнгүй
      </h2>
    </div>
    <div style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :span="4" :offset="20">
          <el-button
            style="margin-left: 105px"
            type="success"
            @click="updateConfig"
            >Шинэчлэх</el-button
          >
        </el-col>
      </el-row>
    </div>
    <h1>{{ driverConfigDetail.rider_type }}</h1>
    <el-form class="panel" style="padding-bottom: 1px; margin-top: 20px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="Харилцагч хүртлэх зай">
            <el-input v-model="driverConfigDetail.radius" placeholder="Radius">
              <template slot="append">м</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-form-item label="Төрөл">
            <el-input
              v-model="driverConfigDetail.rider_type"
              placeholder="rider type"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-form-item label="Хэмжээ">
            <el-input
              v-model="driverConfigDetail.size"
              placeholder="size"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="service">
            <el-select
              multiple
              v-model="driverConfigDetail.service"
              placeholder="service"
            >
              <el-option
                v-for="(item, index) in asd"
                :key="index"
                :label="item.name_mon"
                :value="item.name_eng"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <h3>Ашигтай /1 Харилцагч дээр 2 ойрхон хаягтай захиалга/</h3>
    <div class="panel" style="padding-bottom: 20px">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Захиалга хоорондын мин зөрүү">
              <el-input
                v-model="driverConfigDetail.case1.cl_in_time_limit"
                placeholder="case"
              >
                <template slot="append">мин</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="4" :offset="2">
            <el-form-item label="Үйлчлүүлэгчийн цаг хязгаар">
              <el-input
                v-model="driverConfigDetail.case1.cl_in_time_limit"
                placeholder="case"
              >
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6" :offset="2">
            <el-form-item label="Хэрэглэгч хоорондын зай">
              <el-input
                v-model="driverConfigDetail.case1.user1_user2_dist"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="ashigtai">
              <img
                src="../assets/thumbnail_ashigtai.png"
                alt="5 Terre"
                style="width:100%"
              />
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <h3>Гялалзуур /захиалгагүй жолоочид хамгийн ойр захиалга хувиарлах/</h3>
    <div class="panel" style="padding-bottom: 20px">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Харилцагч хүртлэх зай">
              <el-input
                disabled
                v-model="driverConfigDetail.radius"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item label="Хэрэглэгч хүртлэх зай">
              <el-input
                v-model="driverConfigDetail.case2.cl_user_dist"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="ashigtai">
              <img
                src="../assets/thumbnail_gyalalzuur.png"
                alt="5 Terre"
                style="width:100%"
              />
            </div>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="Үйлчлүүлэгч зай">
              <el-input
                v-model="driverConfigDetail.case2.cl_user_dist"
                placeholder="case"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="2">
            <el-form-item label="Үйлчлүүлэгч жолооч зай">
              <el-input
                v-model="driverConfigDetail.case2.cl_rider_dist"
                placeholder="case"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
    </div>
    <h3>Бүтээмжтэй /2 дахь захиалгын харилцагч нь эхний хэрэглэгчтэй ойр/</h3>
    <div class="panel" style="padding-bottom: 20px">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Хэрэглэгч харилцагч хоёрын зай">
              <el-input
                v-model="driverConfigDetail.case3.user_cl2_dist"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="buteemjtei">
              <img
                src="../assets/thumbnail_buteemjtei.png"
                alt="5 Terre"
                style="width:100%"
              />
            </div>
          </el-col>
          <!-- <el-col :span="4" :offset="2">
            <el-form-item label="Үйлчлүүлэгч2 цаг хязгаар">
              <el-input
                v-model="driverConfigDetail.case3.cl2_in_time_limit"
                placeholder="case"
              >
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </div>
    <h3>
      Арга ядсан /2 ойрхон харилцагчийн захиалгыг 2 ойрхон хэрэглэгчид хүргэх/
    </h3>
    <div class="panel" style="padding-bottom: 20px">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Харилцагч хоорондын зай">
              <el-input
                v-model="driverConfigDetail.case4.cl1_cl2_time_dist"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item label="Захиалга хоорондын хугацааны зөрүү">
              <el-input
                v-model="driverConfigDetail.case4.user1_user2_time_dist"
                placeholder="case"
              >
                <template slot="append">мин</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- <el-col :span="4">
            <el-form-item label="Үйлчлүүлэгч1 үйлчлүүлэгч2 зай">
              <el-input
                v-model="driverConfigDetail.case4.cl1_cl2_dist"
                placeholder="case"
              >
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="Хэрэглэгч хоорондын зай">
              <el-input
                v-model="driverConfigDetail.case4.user1_user2_dist"
                placeholder="case"
              >
                <template slot="append">м</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="arga-ydsan">
              <img
                src="../assets/thumbnail_arga-yadsan.png"
                alt="5 Terre"
                style="width:100%"
              />
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import service from "../helpers/services";
export default {
  name: "SuggesDetail",
  data() {
    return {
      driverConfigUpdate: [],
      asd: [],
      src: "thumbnail_ashigtai.png",
      // url: '',
      driverConfigDetail: {
        radius: "",
        rider_type: "",
        size: "",
        service: [],
        case1: {
          order_time_limit: "",
          // cl_in_time_limit: "",
          user1_user2_dist: ""
        },
        case2: {
          // order_time_limit: "",
          // cl_in_time_limit: "",
          cl_user_dist: "",
          cl_rider_dist: ""
        },
        case3: {
          user_cl2_dist: ""
          // cl2_in_time_limit: ""
        },
        case4: {
          cl1_cl2_time_dist: "",
          user1_user2_time_dist: "",
          // cl1_cl2_dist: "",
          user1_user2_dist: ""
        }
      }
    };
  },
  created() {
    this.getSuggest();
    this.getAllServicess();
  },
  methods: {
    getSuggest() {
      const body = {
        rider_type: this.$route.query.rider_type
      };
      service.getOneDriverConfig(body).then(response => {
        if (response.status === "success") {
          this.driverConfigDetail = response.data;
        }
      });
    },
    getAllServicess() {
      service.getAllServices().then(response => {
        this.asd = response;
      });
    },
    updateConfig() {
      service.updateDriverConfig(this.driverConfigDetail).then(response => {
        if (response.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Амжилттай хадгалаглаа",
            type: "success"
          });
          this.$router.push({
            path: "suggest-list"
          });
        } else if (response.status === "error") {
          this.$notify({
            title: "Алдаа гарлаа",
            message: response.message,
            type: "error"
          });
        }
      });
    }
  }
};
</script>
<style scoped>
div.ashigtai {
  width: 50%;
  margin-left: 50%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
}
div.buteemjtei {
  width: 50%;
  margin-left: 155%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
}
div.arga-ydsan {
  width: 50%;
  margin-left: 155%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
}
</style>
